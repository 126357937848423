/* eslint-disable max-depth */
import type { AgnosticProduct, AgnosticSearchResult } from 'shared/types'
import mustache from 'mustache'
import mainLogo from 'assets/images/logo.svg'
import type { RoutesNamedLocations } from '@typed-router'

function camelCase(s: string) {
  return s.replace(/-./g, (x) => x?.[1]?.toUpperCase() ?? '')
}

type AsfMetasArrayType = {
  key?: string
  name?: string
  property?: string
  content?: string
  rel?: string
  href?: string
}[]
enum AsfMetaFieldType {
  OPEN_GRAPH = 'og'
}

function setMeta(property: string, content: string, metasArray: AsfMetasArrayType, fieldType?: AsfMetaFieldType) {
  const propertyName = fieldType && fieldType === AsfMetaFieldType.OPEN_GRAPH ? 'property' : 'name'

  metasArray.push({
    key: property,
    [propertyName]: property,
    content
  })
}

/* eslint-disable complexity, sonarjs/cognitive-complexity */
export function useSEO() {
  const { $preferences, $i18n: i18n } = useNuxtApp()
  const pageMetaTags = tryParse($preferences.pageMetaTags, {})
  const config = useRuntimeConfig()
  const route = useRoute()
  const { price } = usePrice()

  const { getProductURL, getHostUrl, getURL, getCategoryURL, getLinkParams } = useURL()
  const homeUrl = getHostUrl(getURL({ name: 'index' }))
  const homeLabel = i18n.t('global.home')
  const mode = config.public.isProduction ? 'prd' : 'dev'

  const globalMicrodata = [
    {
      key: 'microdata-SearchAction',
      type: 'application/ld+json',
      innerHTML: JSON.stringify({
        '@context': 'http://schema.org',
        '@type': 'WebSite',
        url: getHostUrl(getURL({ name: 'index' })),
        potentialAction: {
          '@type': 'SearchAction',
          target: getHostUrl(getURL({ name: 'search', query: { q: '' } })) + '{search_term_string}',
          'query-input': 'required name=search_term_string'
        }
      })
    },
    {
      key: 'microdata-Organization',
      type: 'application/ld+json',
      innerHTML: JSON.stringify({
        '@context': 'http://schema.org',
        '@type': 'Organization',
        url: homeUrl,
        // eslint-disable-next-line global-require
        logo: getHostUrl(mainLogo)
      })
    }
  ]

  function getCurrentLocale() {
    return i18n.locales.value.find((l) => l.code === i18n.locale.value) ?? i18n.locales.value[0]
  }

  function getComplexValueForPath(configuration: any, path: string): any {
    const [pathPart, ...rest] = path.split('/').filter(Boolean)
    let localConfig = configuration?.[pathPart ?? ''] ?? {}

    if (configuration?.['*'] && pathPart) {
      const defaultsConfig = { ...configuration['*'] }

      localConfig = deepAssign(defaultsConfig, configuration[pathPart] || {})
    }

    if (rest.length) {
      return getComplexValueForPath(localConfig, rest.join('/'))
    } else {
      return localConfig
    }
  }

  function getAndRenderTagValue(path: string, payload = {}) {
    const currentLocale = getCurrentLocale()

    const template = getComplexValueForPath(
      pageMetaTags,
      `${mode}/live/${currentLocale?.iso?.replace('-', '_')}/${path}`
    )

    if (typeof template === 'string') {
      return mustache.render(template, payload)
    }
  }

  function getBasicSeo(canonicalUrl: string, pageType: string, payload = {}) {
    const site = {
      siteName: getAndRenderTagValue(pageType + '/site-name')
    }
    const request = {
      ...route,
      url: getHostUrl(
        getURL(
          {
            // @ts-expect-error
            name:
              String(route.name || '')
                .split('_')
                .shift() || 'index'
          },
          i18n.locale.value
        )
      )
    }

    const dataToRender = { site, request, ...payload }

    const pageTitle = getAndRenderTagValue(pageType + '/meta-title', dataToRender) || site.siteName
    const meta: AsfMetasArrayType = []

    if (pageTitle) {
      setMeta('title', pageTitle, meta)
      if (process.server) {
        setMeta('og:title', pageTitle, meta, AsfMetaFieldType.OPEN_GRAPH)
        setMeta('twitter:title', getAndRenderTagValue(pageType + '/twitter-title', dataToRender) || pageTitle, meta)
      }
    }
    const description = getAndRenderTagValue(pageType + '/meta-description', dataToRender)
    if (description) {
      setMeta('description', description, meta)
      if (process.server) {
        setMeta('og:description', description, meta, AsfMetaFieldType.OPEN_GRAPH)
        setMeta(
          'twitter:description',
          getAndRenderTagValue(pageType + '/twitter-description', dataToRender) || description,
          meta
        )
      }
    }
    if (process.server) {
      setMeta(
        'og:url',
        getAndRenderTagValue(pageType + '/canonical', dataToRender) ?? canonicalUrl,
        meta,
        AsfMetaFieldType.OPEN_GRAPH
      )
      if (site.siteName) {
        setMeta('og:site_name', site.siteName, meta, AsfMetaFieldType.OPEN_GRAPH)
      }
      const twitterCardContent = pageType === 'product-details' ? 'product' : 'summary'
      setMeta(
        'twitter:card',
        getAndRenderTagValue(pageType + '/twitter-card', dataToRender) ?? twitterCardContent,
        meta
      )

      setMeta('twitter:site', getAndRenderTagValue(pageType + '/twitter-site', dataToRender) || '@username', meta)
      setMeta('twitter:url', getAndRenderTagValue(pageType + '/twitter-url', dataToRender) || canonicalUrl, meta)

      setMeta('robots', getAndRenderTagValue(pageType + '/robots-general', dataToRender) || 'index, follow', meta)

      if ($preferences.googleEngineVerification) {
        setMeta('google-site-verification', $preferences.googleEngineVerification, meta)
      }
    }

    return {
      title: pageTitle,
      description,
      meta: meta
    }
  }

  function appendAltUrls(
    page: Exclude<RoutesNamedLocations, { params: any }>['name'],
    meta: AsfMetasArrayType,
    canonicalUrl: string
  ) {
    const alternateLinks = i18n.locales.value.map((locale) => ({
      key: 'alternate' + locale.code,
      rel: 'alternate',
      href: getHostUrl(getURL({ name: page }, locale.code)),
      hreflang: locale.iso?.toLowerCase() || ''
    }))

    meta.push(
      {
        key: 'canonical',
        rel: 'canonical',
        href: canonicalUrl
      },
      ...alternateLinks
    )
  }

  return {
    setSeoHomepage() {
      const canonicalUrl = getHostUrl(getURL({ name: 'index' }, i18n.locale.value))
      const currentLocale = getCurrentLocale()

      const basicMeta = getBasicSeo(canonicalUrl, 'home', {})
      if (currentLocale?.iso) {
        setMeta('og:locale', currentLocale.iso.replace('-', '_'), basicMeta.meta, AsfMetaFieldType.OPEN_GRAPH)
      }

      appendAltUrls('index', basicMeta.meta, canonicalUrl)
      useHead({
        ...basicMeta,
        script: [
          ...globalMicrodata,
          {
            key: 'microdata-BreadcrumbList',
            type: 'application/ld+json',
            innerHTML: JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'BreadcrumbList',
              itemListElement: [
                {
                  position: 1,
                  '@type': 'ListItem',
                  item: homeUrl,
                  name: homeLabel
                }
              ]
            })
          }
        ]
      })
    },
    setSeoSearch(serchResult: Partial<AgnosticSearchResult>, query: string) {
      const canonicalUrl = getHostUrl(getURL({ name: 'search', query: { q: query } }))

      const params = getLinkParams(serchResult).reduce((acc, link) => {
        return {
          ...acc,
          [link.name]: link.value
        }
      }, {})
      const currentLocale = getCurrentLocale()

      const basicMeta = getBasicSeo(canonicalUrl, 'product-list', { serchResult, query })

      const alternateLinks = i18n.locales.value.map((locale) => ({
        key: 'alternate' + locale.code,
        rel: 'alternate',
        href: getHostUrl(getURL({ name: 'search', query: { q: query, ...params } }, locale.code)),
        hreflang: locale.iso?.toLowerCase() || ''
      }))

      if (currentLocale?.iso && process.server) {
        setMeta('og:locale', currentLocale.iso.replace('-', '_'), basicMeta.meta, AsfMetaFieldType.OPEN_GRAPH)
      }

      const script = [
        ...globalMicrodata,
        {
          key: 'microdata-BreadcrumbList',
          type: 'application/ld+json',
          innerHTML: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                position: 1,
                '@type': 'ListItem',
                item: homeUrl,
                name: homeLabel
              },
              ...(serchResult.breadcrumbs || []).slice(1).map((breadcrumb, position) => ({
                position: position + 2,
                '@type': 'ListItem',
                item: getHostUrl(getCategoryURL(breadcrumb)),
                name: breadcrumb.label
              }))
            ]
          })
        }
      ]
      useHead({
        ...basicMeta,
        link: [
          {
            key: 'canonical',
            rel: 'canonical',
            href: canonicalUrl
          },
          ...alternateLinks
        ],
        script
      })
    },
    setSeoCategory(serchResult: Partial<AgnosticSearchResult>) {
      const category = serchResult.category
      if (!category) {
        return
      }

      const canonicalUrl = getHostUrl(getCategoryURL(category, undefined, i18n.locale.value))

      const params = getLinkParams(serchResult)
      const locales = i18n.locales.value
      const currentLocale = getCurrentLocale()

      const alternates = locales.map((locale) => ({
        rel: 'alternate',
        key: 'alternate' + locale.code,
        href: getHostUrl(getCategoryURL(category, params, locale.code)),
        hreflang: locale.iso?.toLowerCase() || ''
      }))

      const basicSeo = getBasicSeo(canonicalUrl, 'category-landing', { category, serchResult })
      if (currentLocale?.iso && process.server) {
        setMeta('og:locale', currentLocale.iso.replace('-', '_'), basicSeo.meta, AsfMetaFieldType.OPEN_GRAPH)
      }
      if (category.lastModified && process.server) {
        setMeta('og:updated_time', category.lastModified, basicSeo.meta, AsfMetaFieldType.OPEN_GRAPH)
      }

      const link = [
        {
          key: 'canonical',
          rel: 'canonical',
          href: canonicalUrl
        },
        ...alternates
      ]

      const script = [
        ...globalMicrodata,

        {
          key: 'microdata-BreadcrumbList',
          type: 'application/ld+json',
          innerHTML: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                position: 1,
                '@type': 'ListItem',
                item: homeUrl,
                name: homeLabel
              },
              ...(serchResult.breadcrumbs || []).slice(1).map((breadcrumb, position) => ({
                position: position + 2,
                '@type': 'ListItem',
                item: getHostUrl(getCategoryURL(breadcrumb)),
                name: breadcrumb.label
              }))
            ]
          })
        }
      ]

      useHead({
        ...basicSeo,
        link,
        script
      })
    },
    setSeoProduct(product: AgnosticProduct) {
      const canonicalUrl = getHostUrl(getProductURL(product, undefined, i18n.locale.value))

      const locales = i18n.locales.value
      const currentLocale = getCurrentLocale()
      const productUrl = getHostUrl(getProductURL(product, undefined, i18n.locale.value))

      const alternateLinks = locales.map((locale) => ({
        key: 'alternate' + locale.code,
        rel: 'alternate',
        href: getHostUrl(getProductURL(product, undefined, locale.code)),
        hreflang: locale.iso?.toLowerCase() || ''
      }))

      const headData = { meta: [] }

      if (currentLocale?.iso) {
        setMeta('og:locale', currentLocale.iso.replace('-', '_'), headData.meta, AsfMetaFieldType.OPEN_GRAPH)
      }
      if (product.lastModified) {
        setMeta('og:updated_time', product.lastModified, headData.meta, AsfMetaFieldType.OPEN_GRAPH)
      }

      const productImage = product.images.at(0)?.src

      let sku = ''
      let offers: any = {}
      let minPriceValue = ''

      if (productImage) {
        setMeta('og:image', productImage, headData.meta, AsfMetaFieldType.OPEN_GRAPH)
        setMeta('twitter:image', productImage, headData.meta)
      }

      if ('variations' in product) {
        const inStock = product.variations.some((variant) => variant.availability.inStock)
        sku = product.variations[0]?.sku ?? sku
        const currencyCode = product.variations[0]?.price?.currencyCode

        if (product.variations.length) {
          const prices = product.variations.map(
            (variation) => variation.price?.special ?? variation.price?.regular ?? Infinity
          )
          const minPrice = Math.min(...prices)
          const maxPrice = Math.max(...prices)

          if (minPrice === maxPrice) {
            offers = {
              url: productUrl,
              '@type': 'Offer',
              priceCurrency: currencyCode,
              price: minPrice.toFixed(2),
              availability: inStock ? 'http://schema.org/InStock' : 'http://schema.org/OutOfStock'
            }
          } else {
            offers = {
              url: productUrl,
              '@type': 'AggregateOffer',
              offerCount: product.variations.length,
              priceCurrency: currencyCode,
              lowPrice: minPrice.toFixed(2),
              highPrice: maxPrice.toFixed(2),
              availability: inStock ? 'http://schema.org/InStock' : 'http://schema.org/OutOfStock'
            }
          }
          minPriceValue = price(minPrice, currencyCode)
        }
      }

      const basicHead = getBasicSeo(canonicalUrl, 'product-details', { product, minPriceValue })

      const canonical = {
        rel: 'canonical',
        key: 'canonical',
        href: canonicalUrl
      }

      const script = [
        ...globalMicrodata,
        {
          type: 'application/ld+json',
          innerHTML: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'Product',
            name: product.name,
            description: product.metaDescription || product.description,
            mpn: sku,
            sku,
            image: productImage,
            offers
          })
        },
        {
          key: 'microdata-BreadcrumbList',
          type: 'application/ld+json',
          innerHTML: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                position: 1,
                '@type': 'ListItem',
                item: homeUrl,
                name: homeLabel
              },
              ...product.categories.slice(1).map((category, position) => ({
                position: position + 2,
                '@type': 'ListItem',
                item: getHostUrl(getCategoryURL(category)),
                name: category.label
              }))
            ]
          })
        }
      ]
      useHead({
        ...headData,
        ...basicHead,
        meta: [...headData.meta, ...basicHead.meta],
        link: [canonical, ...alternateLinks],
        script
      })
    },
    setSeoCart() {
      const canonicalUrl = getHostUrl(getURL({ name: 'cart' }))
      const currentLocale = getCurrentLocale()

      const basicHead = getBasicSeo(canonicalUrl, 'cart', {})

      if (currentLocale?.iso) {
        setMeta('og:locale', currentLocale.iso.replace('-', '_'), basicHead.meta, AsfMetaFieldType.OPEN_GRAPH)
      }

      appendAltUrls('cart', basicHead.meta, canonicalUrl)

      useHead(basicHead)
    },
    setSeoCheckout(subpage: string) {
      // @ts-expect-error
      const canonicalUrl = getHostUrl(getURL({ name: 'checkout' }))
      const title = i18n.t(`seo.checkout.subpageTitle.${camelCase(subpage)}`)

      const currentLocale = getCurrentLocale()

      const basicSeo = getBasicSeo(canonicalUrl, 'checkout', { title })

      if (currentLocale?.iso) {
        setMeta('og:locale', currentLocale.iso.replace('-', '_'), basicSeo.meta, AsfMetaFieldType.OPEN_GRAPH)
      }

      // @ts-expect-error
      appendAltUrls('checkout', basicSeo.meta, canonicalUrl)

      useHead({
        ...basicSeo,
        script: globalMicrodata
      })
    },
    setSeoAccount(subpage?: string) {
      // @ts-expect-error
      const canonicalUrl = getHostUrl(getURL({ name: 'account' }))

      const title = i18n.t(`seo.account.subpageTitle.${subpage ? camelCase(subpage) : 'basic'}`)
      const description = i18n.t(`seo.account.description.${subpage ? camelCase(subpage) : 'basic'}`)

      const currentLocale = getCurrentLocale()

      const metas: { property: string; content: string }[] = []
      getBasicSeo(canonicalUrl, 'my-account', {
        description,
        title
      })

      if (currentLocale?.iso) {
        setMeta('og:locale', currentLocale.iso.replace('-', '_'), metas, AsfMetaFieldType.OPEN_GRAPH)
      }
      // @ts-expect-error
      appendAltUrls('account', metas, canonicalUrl)
    },
    setSeoLogin() {
      const canonicalUrl = getHostUrl(getURL({ name: 'login' }))

      const title = i18n.t(`seo.account.subpageTitle.login`)
      const description = i18n.t(`seo.account.description.login`)

      const currentLocale = getCurrentLocale()

      const metas: { property: string; content: string }[] = []
      getBasicSeo(canonicalUrl, 'login', {
        description,
        title
      })

      if (currentLocale?.iso) {
        setMeta('og:locale', currentLocale.iso.replace('-', '_'), metas, AsfMetaFieldType.OPEN_GRAPH)
      }
      appendAltUrls('login', metas, canonicalUrl)
    },
    setSeoWishList() {
      const canonicalUrl = getHostUrl(getURL({ name: 'wishlist' }))
      const title = i18n.t(`seo.wishlist.title`)
      const description = i18n.t(`seo.wishlist.description`)

      const metas: { property: string; content: string }[] = []
      getBasicSeo(canonicalUrl, 'wishlist', {
        description,
        title
      })
      const currentLocale = getCurrentLocale()

      if (currentLocale?.iso) {
        setMeta('og:locale', currentLocale.iso.replace('-', '_'), metas, AsfMetaFieldType.OPEN_GRAPH)
      }
      appendAltUrls('wishlist', metas, canonicalUrl)
    },
    setSeoTrackOrder() {
      const canonicalUrl = getHostUrl(getURL({ name: 'order-trackform' }))
      const title = i18n.t(`seo.trackOrder.title`)
      const description = i18n.t(`seo.trackOrder.description`)

      const seo = getBasicSeo(canonicalUrl, 'wishlist', {
        description,
        title
      })
      useHead(seo)
    },
    setSeoPagination(serchResult: Partial<AgnosticSearchResult>, query?: string) {
      const pagination = serchResult.pagination
      if (pagination && pagination.totalPages > 1) {
        const params = getLinkParams({ ...serchResult, ...{ pagination: undefined } })

        const getLinkHref = (linkParams: typeof params) => {
          if (serchResult.category) {
            return getHostUrl(getCategoryURL(serchResult.category, linkParams, i18n.locale.value))
          } else if (query) {
            const parameters = linkParams.reduce((acc, link) => {
              return {
                ...acc,
                [link.name]: link.value
              }
            }, {})
            return getHostUrl(getURL({ name: 'search', query: { q: query, ...parameters } }))
          }
        }

        if (pagination.totalPages > pagination.currentPage) {
          const paramsNextLink = [...params, { name: 'page', value: String(pagination.currentPage + 1) }]

          useHead({
            link: [
              {
                key: 'pagination-next',
                rel: 'next',
                href: getLinkHref(paramsNextLink)
              }
            ]
          })
        }
        if (pagination.currentPage > 1) {
          const paramsPrevLink = [...params, { name: 'page', value: String(pagination.currentPage - 1) }]

          useHead({
            link: [
              {
                key: 'pagination-prev',
                rel: 'prev',
                href: getLinkHref(paramsPrevLink)
              }
            ]
          })
        }
      }
    }
  }
}
